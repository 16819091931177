import logo from "assets/images/brands/434bb57c-88d9-4b0d-a0c8-fb94eebf144b.png";
import nightLogo from "assets/images/brands/af3e8578-46bf-43d4-96a2-aca7df37e940.png";


export const clientName = "Tradefx Pte.Ltd";
export const developedBy = "Exinitic";
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL || "http://localhost:3001/static/";
export const cpUrl = process.env.REACT_APP_CP_URL || "http://localhost:4002";
export const apiUrl = process.env.REACT_APP_API_CP_DOMAIN || "http://localhost:3001";
export const companyName =  "Tradefx Pte.Ltd";
export const companyWebsite = "https://www.tradefx.co";
export const privacyPolicyLink = "https://www.tradefx.co/privacy-policy/";
export const clientAgreementLink = "";
export const sidebarLogo = logo;
export const smLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;
export const nightMode = nightLogo;